import React, {useState, createContext} from "react";
import {IntlProvider} from "react-intl";

import siteMessageFr from "../assets/data/locales/fr.json"
import siteMessageEn from "../assets/data/locales/en.json"
import moment from "moment";
import "moment/locale/fr"
import "moment/locale/es"
import "moment/locale/en-au"

const allMessage = {
    fr:{...siteMessageFr},
    en:{...siteMessageEn}
}
const Context = createContext();

const IntlProviderWrapper = ({children}) => {
    // ** States
    const [locale, setLocale] = useState('fr')
    const [messages, setMessages] = useState(allMessage['fr'])
    moment.locale(locale)

    // ** Switches Language
    const switchLanguage = lang => {
        setLocale(lang)
        setMessages(allMessage[lang])
    }

    return (
        <Context.Provider value={{locale, switchLanguage, messages}}>
            <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='fr'>
                {children}
            </IntlProvider>
        </Context.Provider>
    )
}

export {IntlProviderWrapper, Context as IntlContext}