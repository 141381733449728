import React, { useContext } from 'react';
import {IntlContext} from "../../../context/Internationalization";
import LogSvg from "../../../assets/images/logo/logo.svg"
import {NavLink} from "react-router-dom";

function Index() {
  const {messages} = useContext(IntlContext);
  return (
    <div className='footerMain flex-column justify-content-center align-items-center'>
      <div className='quickLink w-100'>
        <div className='container w-100'>
          <div className='row d-flex justify-content-between align-items-start py-5'>
            <div className='col-12 col-md-6'>
              <div className='col-12 col-md-10'>
                <img src={LogSvg} alt='logo' className='d-footer-logo' />
                <p className='quickLinkDescription mt-2'>{ messages['footerText1'] }</p>
              </div>
            </div>
            <div className='col-12 col-md-3'>
              <p className='quickLinkTitle mt-2'>{ messages['COMPANY'] }</p>           
              <p className='quickLinkText'>
                <NavLink to="/about" className="link-itemes">{messages["about"]}</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/da-remit" className="link-itemes">{messages["Entreprise"]}</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/global-presence" className="link-itemes">{messages["Presenceglobale"]}</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/contact" className="link-itemes">{ messages['contact'] }</NavLink>
              </p>
            </div>
            <div className='col-12 col-md-3'>
              <p className='quickLinkTitle mt-2'>{ messages['INFORMATION'] }</p>
              <p className='quickLinkText'>
                <NavLink to="/privacy-policy" className="link-itemes" data-testid="privacy-policy-test">{ messages['PrivacyPolicy'] }</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/terms-conditions" className="link-itemes" data-testid="terms-conditions-test">{ messages['termesetConditions'] }</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/cookie-policy" className="link-itemes" data-testid="cookie-policy-test">{ messages['cookiePolicy'] }</NavLink>
              </p>
              <p className='quickLinkText'>
                <NavLink to="/disclaimer" className="link-itemes" data-testid="disclaimer-test">{ messages['disclaimer'] }</NavLink>
              </p>
            </div>
          </div>
          <hr />
          <div className='copyrightText'>
            <p>© Copyright 2023, { messages['footerText2'] } <br /> { messages['footerText3'] }</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
