
import {lazy} from 'react'

const Siteroutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/landing-page/')),
        exact: true
    },
    {
        path: '/about',
        component: lazy(() => import('../../pages/about/')),
        exact: true,
        ind:{}
    },
    {
        path: '/certifications',
        component: lazy(() => import('../../pages/certifications/')),
        exact: true,
    },
    {
        path: '/history',
        component: lazy(() => import('../../pages/history/')),
        exact: true,
        ind:{}
    },
    {
        path: '/clients',
        component: lazy(() => import('../../pages/clients/')),
        exact: true,
        ind:{}
    },

    {
        path: '/contact',
        component: lazy(() => import('../../pages/contact/')),
        exact: true,
    },
    {
        path: '/milestones-values',
        component: lazy(() => import('../../pages/values/')),
    },
    {
        path: '/global-presence',
        component: lazy(() => import('../../pages/global_presence/')),
        exact: true,
    },
    {
        path: '/crs',
        component: lazy(() => import('../../pages/crs/')),
        exact: true,
    },
    {
        path: '/da-remit',
        component: lazy(() => import('../../pages/da_remit/')),
        exact: true,
    },
    {
        path: '/da-ride',
        component: lazy(() => import('../../pages/da_ride/')),
    },
    {
        path: 'mission-vision',
        component: lazy(() => import('../../pages/vision/')),
        exact: true,
    },
    {
        path: '/trading',
        component: lazy(() => import('../../pages/trading/')),
        exact: true,
    },
    {
        path: '/organic-agriproducts',
        component: lazy(() => import('../../pages/organicAgriproducts/')),
        exact: true,
    },
    {
        path: '/privacy-policy',
        component: lazy(() => import('../../pages/information/privacy-policy/')),
        exact: true,
    },
    {
        path: '/cookie-policy',
        component: lazy(() => import('../../pages/information/cookie-policy/')),
        exact: true,
    },
    {
        path: '/disclaimer',
        component: lazy(() => import('../../pages/information/disclaimer/')),
        exact: true,
    },
    {
        path: '/terms-conditions',
        component: lazy(() => import('../../pages/information/terms-and-conditions/')),
        exact: true,
    },

]

export default  Siteroutes;
