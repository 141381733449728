import React from "react";
import { Route, Routes as AppRoutes } from 'react-router-dom'
import { Routes as SiteRoutes, RoutesAdmin, AdminauthRoutes } from "./routes";
import { SiteLayout, AdminLayout } from "../layout";
import "../assets/style/loader.css"
import {E404} from "../pages/error";
import {SiteLoader} from "../components/elements/loaders";

const Routes = () => {
    return (
        <AppRoutes>
            <Route path="/" element={<SiteLayout/>}>
             {
                 SiteRoutes.map((item, index) => {
                        const Component = item.component
                        return(
                            <Route path={item.path} index={item.index} {...item.ind} element = {
                                <React.Suspense fallback={<SiteLoader/>}>
                                    {<Component />}
                                </React.Suspense>
                            } key={index} />
                        )
                    })
                }
            </Route>
            {
                sessionStorage.getItem("isLog")

                ?

                <Route path="/admin/" element={<AdminLayout/>}>
                    {
                        RoutesAdmin.map((item, key)=>{
                            const Componentelement = item.component
                            return(
                                <Route  path={item.path} index={item.index} {...item.ind}   element={
                                    <React.Suspense fallback={<SiteLoader/>}>
                                        {<Componentelement />}
                                    </React.Suspense>
                                } key={key} />
                            )
                        })
                    }
                </Route>

                :

                <Route path="/admin" >
                    {
                        AdminauthRoutes.map((item,key)=>{
                        const Component=item.component
                        return(
                            <Route  path={item.path} index={item.index} {...item.ind}   element={
                                <React.Suspense fallback={<SiteLoader/>}>
                                    {<Component />}
                                </React.Suspense>
                            } key={key} />
                        )
                    })
                    }
                </Route>

            }

            <Route path="*" element={<E404/>}/>
        </AppRoutes>
    )
}
export default Routes;