import React from 'react';
import ReactDOM from "react-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import 'font-awesome/css/font-awesome.min.css';
import {IntlProviderWrapper} from "./context/Internationalization";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./assets/style/bgAnim.css"
import "./assets/style/main.css"


ReactDOM.render(
  <IntlProviderWrapper>
    <BrowserRouter>
      <App/>
    </BrowserRouter>
    <ToastContainer />
  </IntlProviderWrapper>,
  document.getElementById('root')
);
