import React,{lazy} from "react";
import {Outlet} from "react-router-dom";
import {SiteLoader} from "../components/elements/loaders";
const Site = lazy(() => import("./site"))
const Admin=lazy(()=>import("./admin"))
export const SiteLayout = (props) => {
    return(
        <React.Suspense fallback={<SiteLoader/>}>
            <Site>
                <Outlet {...props}/>
            </Site>
        </React.Suspense>
    )
}

export const AdminLayout=(props)=>{
    return(
        <React.Suspense fallback={<SiteLoader/>}>
        <Admin>
            <Outlet {...props}/>
        </Admin>
        </React.Suspense>
    )
}
