import React, { useContext } from "react";
import {IntlContext} from "../../context/Internationalization";
import { Link } from "react-router-dom";
import "../../assets/style/errorStyle.css"
import Footer from "../../components/global/footer";

const Erreur404 = () => {
    const {messages} = useContext(IntlContext);
    return(
        <div className="main-section">
            <div className="d-flex align-item-center justify-content-center">
                <div className="container-error">
                    <h1>404</h1>
                    <p className="col-11 col-md-6 error-text">
                        { messages['404Text1'] } <Link to="/" className="text-site go-back">{ messages['Goback'] }</Link>
                    </p>
                </div>
            </div>

            <Footer />
        </div>
    )
}
export default Erreur404;