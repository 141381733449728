
import {lazy} from 'react'

const AdminRoutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/admin/contact/')),
        exact: true
    },
    {
        path: '/admin/testimonials',
        component: lazy(() => import('../../pages/admin/testimonials/')),
        exact: true,
        ind:{}
    },
]

const authRoutes = [
    {
        ind:{
            index:true,
        },
        component: lazy(() => import('../../pages/admin/home/')),
        exact: true
    },

    // {
    //     path: '/admin/register',
    //     component: lazy(() => import('../../pages/admin/register/')),
    //     exact: true,
    //     ind:{}
    // }
]

export {AdminRoutes, authRoutes};
