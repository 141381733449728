import React, {useContext, useEffect} from "react"
import Routes from "./routeur/Routers"
import {IntlContext} from "./context/Internationalization";


const App = () => {
    const {switchLanguage} = useContext(IntlContext)

    useEffect(() => {
        checkLanguageAndUpdateIfNeeded();
    })
    const handleLangUpdate = (lang) => {
        switchLanguage(lang)
    }
    const checkLanguageAndUpdateIfNeeded = () => {
        localStorage.getItem("forcedLang") === null || localStorage.getItem("forcedLang") === undefined 
        ? handleLangUpdate(window.navigator.language.slice(0, 2)) 
        : handleLangUpdate(localStorage.getItem("forcedLang"))
    }

    return (
      <Routes/>
    )
}
export default App;