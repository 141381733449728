import Siteroutes from "./siteroutes";
import {AdminRoutes, authRoutes }from "./adminRoutes";

const Routes = [
    ...Siteroutes
]
const RoutesAdmin = [
    ...AdminRoutes
]

const AdminauthRoutes = [
    ...authRoutes
]
 
export {Routes, RoutesAdmin, AdminauthRoutes}